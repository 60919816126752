import { makeTranslation } from "../lib/translation"

export const translations = makeTranslation({
  navigation: {
    home: {
      en: "Home",
      sr: "Naslovna",
    },
    aboutUs: {
      en: "About us",
      sr: "O nama",
    },
    gallery: {
      en: "Gallery",
      sr: "Galerija",
    },
    assortment: {
      en: "Products",
      sr: "Naš asortiman",
    },
    contact: {
      en: "Contact",
      sr: "Kontakt",
    },
    shop: {
      en: "Shop",
      sr: "Prodavnica",
    },
    myAccount: {
      en: 'My profile',
      sr: 'Moj nalog'
    },
    login: {
      en: 'Login',
      sr: 'Prijava'
    },
    registration: {
      en: 'Registration',
      sr: 'Registracija'
    }
  },
  home: {
    title: {
      en: "Welcome to <br/> <b>Evrotom!</b>",
      sr: "Dobrodošli u <br/> <b>Evrotom!</b>",
    },
    leftButton: {
      en: "About us",
      sr: "O nama",
    },
    rightButton: {
      en: "Shop",
      sr: "Prodavnica",
    },
    allFromBees: {
      en: 'ALL FOR BEES AND ALL FROM BEES!',
      sr: 'SVE ZA PČELE I SVE OD PČELA!'
    }
  },
  aboutus: {
    title: {
      en: "MORE THAN THREE DECADES OF EXPERIENCE",
      sr: "VIŠE OD TRI DECENIJE ISKUSTVA",
    },
    about: {
      en: `<b>EVROTOM</b> is a family company that has been producing beekeeping equipment,
      medicines and bee food since 1991. Grandpa Toma, a lover of bees, started
      producing food and medicine for bees 30 years ago. First for himself and his bees,
      and when he was convinced of the quality of his products for other beekeepers and
      their bees. As the business expanded, so did our capacity. In order to complete our
      range, with all the necessary tools for beekeepers, in the beginning of 2000 we
      started the production of beekeeping equipment. Now our company has 35
      employees, at 3 locations in Ruma. EUROTOM products are available in most
      countries in Europe, as well as in the Middle East, with the constant opening of
      new markets. Through daily work and effort, we strive to expand our range and
      capacity.`,
      sr: `<b>EVROTOM</b> je porodična kompanija koja se bavi proizvodnjom pčelarske oprema, lekova i hrane za pčele od 1991. godine. Deda Toma, zaljubljenik u pčele, započeo je proizvodnju hrane i lekova za pčele pre 30 godina. Prvo za sebe i svoje pčele, a kada se uverio u kvalitet svojih proizvoda i za druge pčelare i njihove pčele. Kako se posao širio, povećali su se i naši kapaciteti. U želji da upotpunimo naš asortiman, sa svim potrebnim alatima za pčelare, početkom 2000. godina započeli smo proizvodnju pčelarske opreme. Sada naša firma broji 35 zaposlenih, na 3 lokacije u Rumi. <b>EVROTOM</b> proizvodi su dostupni u većini država u Evropi, kao i u državama Bliskog Istoka, uz stalno otvaranje novih tržišta. Svakodnevnim radom i trudom težimo da proširimo naš asortiman i kapacitete.`,
    },
    missionTitle: {
      en: "Mission",
      sr: "Misija",
    },
    visionTitle: {
      en: "Vision",
      sr: "Vizija",
    },
    mission: {
      en: `Protect bees and improve beekeeping with wide palette of quality products with
      affordable prices. Improve our products every day into more practical, more
      economical and ecologicaly acceptable. Provide our workers with excellent working
      conditions, while developing infrastructure, both in the domestic and foreign
      markets.`,
      sr:
        "Misija Očuvati pčelarstvo širokom ponudom kvalitetnih proizvoda uz povoljne cene. Svakodnevnim radom unaprediti naše proizvode u praktičnije, ekonomičnije i ekološki prihvatljivije. Pružiti našim radnicima odlične uslove za rad, dok razvijamo infrastrukturu, kako na domaćem tako i stranom tržištu.",
    },
    vision: {
      en: `Our vision is to make <b>EVROTOM</b> into one of synonyms for beekeeping and to become
      number 1 producer of bee medicine, bee feed and beekeeping equipment from
      Balkans.`,
      sr: `Vizija Naša vizija je da <b>EVROTOM</b> bude jedan od sinonima za pčelarstvo.Da postanemo broj jedan proizvođač lekova i hrane za pčele i opreme za pčelarstvo sa Balkana.`,
    },
    skills: {
      equipment: {
        en: "BEEKEEPING EQUIPMENT",
        sr: "OPREMA ZA PČELARSTVO",
      },
      tools: {
        en: "BEEKEEPING ACCESSORIES",
        sr: "PRIBOR ZA PČELARSTVO",
      },
      medicine: {
        en: "MEDICINE AND PREPARATIONS FOR BEES",
        sr: "LEKOVI ZA PČELE",
      },
      food: {
        en: "BEE FOOD",
        sr: "HRANA I SUPLEMENTI ZA PČELE",
      },
      protection: {
        en: "BEEKEEPING PROTECTIVE EQUIPMENT",
        sr: "ZAŠTITNA OPREMA",
      },
      products: {
        en: "BEE PRODUCTS",
        sr: "PČELINJI PROIZVODI",
      },
    },
  },
  assortment: {
    title: {
      en: "OUR ASSORTMENT",
      sr: "NAŠ ASORTIMAN",
    },
    equipment: {
      title: {
        en: "BEEKEEPING </BR> EQUIPMENT",
        sr: "OPREMA <br/> ZA PČELARSTVO",
      },
      description: {
        en: `Extracting and uncapping equipment, wax foundation machines, wax melters,
        pollen dryers, decristalization equipment and buckets.`,
        sr: `Oprema za otklapanje, vrcanje i skladištenje meda. Linije za proizvodnju satnih
        osnova, topionici voska, komore za dekristalizaciju meda i sušenje polena.`,
      },
    },
    tools: {
      title: {
        en: "TOOLS FOR <br/> BEEKEEPING",
        sr: "PRIBOR <br/> ZA PČELARSTVO",
      },
      description: {
        en: `Beekeeping knives, bee smokers, uncapping forks, pollen collectors, breading tools
        for queen bee and much more.`,
        sr:
          `Noževi, dimilice, viljuške, sakupljači polena, pribor za rad sa maticom i mnogi
          drugi.`,
      },
    },
    medicine: {
      title: {
        en: "MEDICINE AND SUPPLEMENTS <br/> PREPARATIONS FOR BEES",
        sr: "LEKOVI I SUPLEMENTI <br/> ZA PČELE",
      },
      description: {
        en: `Wide range of efficient medicine and supplements for prevention and treating bee
        diseases as well as strengthening bee colonies.`,
        sr:
          "Čitava paleta veoma efikasnih i proverenih lekova i preparata za prevenciju i lečenje pčela.",
      },
    },
    food: {
      title: {
        en: "BEE CAKES <br/> AND FEED",
        sr: "HRANA <br/> ZA PČELE",
      },
      description: {
        en: `For stronger and healthier bee colonies we have sugar, stimulant, pollen and
        Nozevit bee cakes.`,
        sr:
          "Za zdrava i snažna pčelinja društva u našoj ponudi su Apitom, Apitom-S, Apitom-P.",
      },
    },
    protection: {
      title: {
        en: "PROTECTIVE <br/> BEEKEEPING EQUIPMENT",
        sr: "ZAŠTITNA <br/> OPREMA",
      },
      description: {
        en: "Gloves, hats, suits, three layered suit, throusers and shirts.",
        sr:
          "Pčelarska zaštitna odeća u vidu kombinezona, bluza, šešira i rukavica.",
      },
    },
    products: {
      title: {
        en: "BEE PRODUCTS",
        sr: "PČELINJI <br/> PROIZVODI",
      },
      description: {
        en: `ApiFito program and Apina cosmetics, several types of honey, royal jelly, propolis,
        and pollen`,
        sr:
          "Api fito program, Apina kozmetika, nekoliko vrsta meda, matična mleč, propolis i polen.",
      },
    },
  },
  accomplishments: {
    awards: {
      en: "Awards",
      sr: "Awards",
    },
    clients: {
      en: "Clients",
      sr: "Clients",
    },
    team: {
      sr: "Team",
      en: "Team",
    },
    project: {
      sr: "Project",
      en: "Project",
    },
  },
  gallery: {
    title: {
      en: "Gallery",
      sr: "Galerija",
    },
    production: {
      en: "Production",
      sr: "Proizvodnja",
    },
    products: {
      en: "Products",
      sr: "Proizvodi",
    },
    workspace: {
      en: "Headquarters",
      sr: "Poslovni prostor",
    },
    team: {
      en: "Our team",
      sr: "Naš tim",
    },
    all: {
      en: "All",
      sr: "Sve",
    },
  },
  featuredProducts: {
    kasetna: {
      title: {
        en: 'Kasetna centrifuga 18 DB ramova',
        sr: 'Self-turning extractor 18 DB frames'
      },
      link: {
        en: 'https://new.evrotom.org/shop/en/shop/varolitom-za-prihranu-pcela/',
        sr: 'https://new.evrotom.org/shop/varolitom-za-prihranu-pcela/'
      },
      description: {
        en: `Self-turning extractor for 18 DB frames, 9 double cassettes with digital controller.
        The electric motor that drives it has a power of 550W and is connected by a 1: 5 gear ratio.
        Self-turning extractor provides fast and efficient extracting.
        Frames rotate automatically whith change of rotation direction.
        When extracting, honey comes out complitely, and partitin between the double cassettes ensures that the honey does not leak from one frame to another.
        The maximum size of the frame that can fit in the basket of this extractor is 480 x 330 mm.
        It is made of INOX 304 sheet metal 0.8 mm thick with INOX 304 legs, beam and two taps.
        This makes it long-lasting, and above all suitable for the food industry and work with honey.
        The transparent lid allows a view inside the centrifuge and monitoring the release of honey during vomiting.
        These features and double cassettes make it suitable for professional beekeepers who want a quality centrifuge that will serve them for many years and that will shorten the spinning time.`,
        sr: `Kasetna centrifuga za 18 ramova DB, sa 9 duplih kaseta, sa električnim pogonom na 220V sa digitalnom automatikom. 
        Elektromotor koji je pokreće ima snagu od 550W i povezan reduktorom prenosnog odnosa 1:5. 
        Kasetna centrifuga omogućuje brzo i efikasno vrcanje meda. 
        Ramovi se ne moraju okretati, jer prilikom promene smera obrtanja menja se i položaj ramova. 
        Prilikom vrcanja med izlazi u potpunosti, a pregrada izmedju duplih kaseta osigurava da med sa jednog rama ne curi na drugi ram. 
        Maksimalna veličina rama koji može da stane u koš ove centrifuge je 480 x 330 mm. 
        Izrađena je od INOX 304 lima 0.8 mm debljine sa INOX 304 nogama, gredom i dve slavine. 
        To je čini dugotrajnom, a pre svega pogodnom za prehrambenu industriju i rad sa medom. 
        Providan poklopac omogućava pogled unutar centrifuge i praćenje izlaska meda tokom vrcanja. 
        Ove karakteristike i duple kasete je čine pogodnom za pčelare profesionalce koji žele kvalitetnu centrifugu koja će ih služiti duži niz godina i koja će skratiti vreme vrcanja.`
      },
      popupOne: {
        en: `Outside diameter: Ø1270 mm
        Inner diameter: Ø1250 mm
        Height with legs: 1100mm`,
        sr: `Spoljašnji prečnik: Ø1270 mm 
        Unutrašnji prečnik: Ø1250 mm 
        Visina na nogama: 1100mm`
      },
      popupTwo: {
        en: `Digital controller has 6 programs with 6 steps.
        Each program can be modified and stored in memory.`,
        sr: `Digitalna automatika koja upravlja motorom poseduje 6 programa sa 6 koraka.
        Svaki program se može menjati i čuvati u memoriji.`
      }
    },
    varolitom: {
      title: {
        en: 'VaroLiTom',
        sr: 'VaroLiTom'
      },
      link: {
        en: 'https://new.evrotom.org/shop/en/shop/varolitom-za-prihranu-pcela/',
        sr: 'https://new.evrotom.org/shop/varolitom-za-prihranu-pcela/'
      },
      image: {
        en: 'varolitom.jpg',
        sr: 'varolitom.jpg',
      },
      description: {
        en: `Supplement for bee feed, with high efficacy agains Varroa destructor.
        Instructions for use: One bag of VaroiTom is dissolved in 10l of cool 30% sugar syrup.
        That way, you have 10l of supplement which is enough for treatment of 10 bee colonies. Each colony should recieve 1l of syrup.
        Adding of syrup should be done three times, with six days in between, which is why package of VaroLiTom has 3 bags inside.
        Feeding the colonies should be done according to instruction and scheme enclosed on the packaging.
        Adding this supplement should be done after removing frames with honey.`,
        sr: `Suplement za prihranu pčela, veoma efikasan u suzbijanju Varroe.
        Nacin primene: Kesicu preparata VaroLiTom rastvoriti u 10 litara
        ohladenog 30% vodeno-šecernog sirupa.
        Tako se dobija kolicina od 10 litara suplementa VaroLiTom, koja je
        dovoljna za tretman 10 pcelinjih zajednica, jer svakoj treba dati po
        po jedan litar pripremljenog rastvora. Prihrana se obavlja u tri
        ciklusa u razmaku od po šest dana, te je za svaki ciklus u pakovanju
        priložena po jedna kesica. Prihranu obaviti u skladu sa upustvom i
        šemom prikazanim na pakovanju. Kompletnu prihranu
        obaviti nakon uklanjanja nastavaka sa medom.`
      }
    },
    vrcaljka: {
      title: {
        en: 'Manual honey extractor 4 LR frames',
        sr: 'Ručna vrcaljka za 4 LR rama'
      },
      link: {
        en: '',
        sr: ''
      },
      description: {
        en: `Maximal frame size which can fit into basket of this extractor is 480 x 275 mm
        It is quite mobile, given small dimensions and weight, with  removable legs.
        Tangential extractor has big efficiency, with ability to extract all honey from frames.
        These characteristic make it suitable for beekeepers with smaller number of beehives, begginers and hobbysts who want quality extractor which will serve them for long time.`,
        sr: `Maksimalna veličina rama koji može da stane u koš ove centrifuge je 480 x 275 mm.  
        Malih je dimenzija i mase sa demontažnim nogicama, što je sve zajedno čini lako prenosivom. 
        Tangencijalna centrifuga ima veoma veliku efikasnost, jer prilikom vrcanja može izvrcati sav med iz ramova.
        Ove karakteristike je čine pogodnom za pčelare sa manjim brojem košnica, početnike i hobiste koji žele kvalitetnu centrifugu koja će ih služiti duzi niz godina.`
      },
      popupOne: {
        en: `Barell: 0.6 mm INOX 304 l   tin     
        Tap: INOX 304                
        Basket: INOX 304`,
        sr: `Bure: 0.6 mm INOX 304 lim         
        Slavina: INOX 304                
        Koš: INOX 304`
      },
      popupTwo: {
        en: `Cover: See-trough Akrylon 4mm
            Outside diameter: Ø525 mm
            Height with legs: 950 mm`,
        sr: `Poklopac: Providni Akrylon 4mm
            Spoljašnji prečnik: Ø525 mm
            Visina na nogama: 950 mm`
      }
    },
    separator: {
      title: {
        en: 'Professional wax separator',
        sr: 'Profesionalni separator voska'
      },
      link: {
        en: 'https://new.evrotom.org/shop/en/shop/professional-wax-separator/',
        sr: 'https://new.evrotom.org/shop/profesionalni-separator-voska/'
      },
      image: {
        en: 'separator.jpg',
        sr: 'separator.jpg',
      },
      description: {
        en: `A professional wax separator is a machine that filters honey during the extraction process, separates honey and honey caps and all other impurities that ultimately pose a problem. 
        It is made of INOX 304 sheet metal 0.8 mm thick with INOX 304 beam and plasticized legs. 
        This makes it long-lasting, and above all suitable for the food industry and work with honey. 
        The transparent lid allows a view inside the separator and monitoring the honey output during operation. 
        The engine is positioned at the bottom and power is 370W. 
        The capacity of this machine is up to 300kg of honey per hour, depending on the centrifuges capacity used along with him. 
        For a detailed explanation of how this machine is used and works, watch the video.`,
        sr: `Profesionalni separator voska je mašina koja filtrira med u toku procesa vrcanja, odvaja med i medne poklopce i sve ostale nečistoće koje na kraju predstavljaju problem. 
        Izrađen je od INOX 304 lima 0.8 mm debljine sa INOX 304 gredom i plastificiranim nogama. 
        To ga čini dugotrajnim, a pre svega pogodnim za prehrambenu industriju i rad sa medom. 
        Providan poklopac omogućava pogled unutar separatora i praćenje izlaska meda tokom rada. 
        Motor je postavljen sa donje strane i snage je 370W. 
        Kapacitet ove mašine je do 300kg meda na sat, u zavisnost od kapaciteta centrifuge koja se koristi uporedo sa njim. 
        Za detaljno objašnjenje kako se koristi i radi ova mašina pogledajte video.`
      },
      popupOne: {
        en: `Barrel: 0.8 mm INOX 304 sheet metal
        Outer diameter: Ø880 mm 
        Height: 1200 mm
        Cover: Transparent Acrylon 4mm`,
        sr: `Bure: 0.8 mm INOX 304 lim
        Spoljašnji prečnik: Ø880 mm 
        Visina: 1200 mm
        Poklopac: Providni Akrylon 4mm`
      },
      popupTwo: {
        en: `To check the video how to use Separatot, please visit following <a target="_blank" href='https://youtu.be/K4kK0vcaPiY>link:</a>`,
        sr: `Video kako se koristi Separator možete pogledati na sledećem <a target="_blank" href='https://youtu.be/K4kK0vcaPiY>linku:</a>`
      }
    },

  },
  footer: {
    input: {
      en: "Enter your email adress!",
      sr: "Unesite Vašu e-mail adresu!",
    },
    address: {
      line1: {
        en: "",
        sr: "D.O.O. Za spoljnu i unutrašnju trgovinu i proizvodnju",
      },
      line2: {
        en: "",
        sr: "22400 Ruma, Kraljevačka 46. Republika Srbija",
      },
      line3: {
        en: "",
        sr: "tel: +381 (0) 22 479 569, fax: +381 (0) 22 471 675",
      },
    },
    login: {
      en: "Login and registration",
      sr: "Prijava i registracija",
    },
    contactUs: {
      en: "Contact us",
      sr: "Kontaktirajte nas",
    },
  },
  forms: {
    contactTitle: {
      en: 'Get in touch with us',
      sr: 'Stupite u kontakt'
    },
    name: {
      en: 'First and last name',
      sr: 'Ime i prezime',
    },
    subject: {
      en: 'Subject',
      sr: 'Naslov',
    },
    email: {
      en: 'Email address',
      sr: 'Email adresa',
    },
    message: {
      en: 'Message',
      sr: 'Vaša poruka'
    },
    send: {
      en: 'Send',
      sr: 'Pošalji'
    }
  },
  contactPage: {
    title: {
      en: 'Contact',
      sr: 'Kontakt'
    },
    workHoursTitle: {
      en: 'Work hour',
      sr: 'Radno vreme'
    },
    workHours1: {
      en: 'Monday – Saturday: 08:00 to 15:00',
      sr: 'Ponedeljak – Petak: 08:00 do 15:00' 
    },
    workHours2: {
      en: 'Saturday : 08:00 to 13:00',
      sr: 'Subota : 08:00 do 13:00' 
    },
    hq: {
      en: 'Headquarters',
      sr: 'Sedište kompanije'
    },
    representatives: {
      en: 'Representatives',
      sr: 'Predstaništva'
    },
    form: {
      en: 'Fill out the contact form below and do not hesitate to ask us anything. We will do our best to respond you as soon as possible! <br/> <b>EVROTOM DOO</b>',
      sr: 'Popunite e-mail kontakt formu i izložite nam vaša pitanja! Mi ćemo se potruditi da Vam odgovorimo u najskorijem roku! <br/> <b>EVROTOM DOO</b>'
    },
    countries: {
      croatia: {
        en: 'Croatia',
        sr: 'Hrvatska'
      },
      mac: {
        en: 'Macedonia',
        sr: 'Makedonija'
      },
      bul: {
        en: 'Bulgaria',
        sr: 'Bugarska'
      },
      ukr: {
        en: 'Ukraine',
        sr: 'Ukrajina'
      },
      baltic: {
        en: 'BALTIC COUNTRIES (LATVIA,LITHUANIA, ESTONIA)',
        sr: `BALTIČKE DRŽAVE (LETONIJA,LITVANIJA, ESTONIJA)`
      },
      scan: {
        en: 'SCANDINAVIAN COUNTRIES (SWEDEN, NORWAY, DENMARK)',
        sr: 'SKANDINAVSKE DRŽAVE (ŠVEDSKA, NORVEŠKA, DANSKA)'
      },
      cz: {
        en: 'SLOVAKIA AND CZECHIA',
        sr: 'SLOVAČKA I ČEŠKA'
      },
      description: {
        croatia: {
          en: `<b>Contact:</b> Silvio Cvitković <br/>
          H.V. Hrvatinića 38, 32100 <br/> Vinkovci, Croatia <br/>
          <b>Phone:</b> +385 32 307 014 <br/>
          <b>Mobile:</b> +385 98 933 77 74 <br/>
          <a class='mailto' href='mailto:euroapishr@hotmail.com'>euroapishr@hotmail.com</a>`,
          sr: `<b>Kontakt:</b> Silvio Cvitković <br/>
          H.V. Hrvatinića 38, 32100 <br/> Vinkovci, Hrvatska <br/>
          <b>Tel:</b> +385 32 307 014 <br/>
          <b>Mob:</b> +385 98 933 77 74 <br/>
          <a class='mailto' href='mailto:euroapishr@hotmail.com'>euroapishr@hotmail.com</a>`
        },
        mac: {
          en: `<b>Contact:</b> Aneta Stojkova <br/>
          lindenska , Čaška <br/> Macedonia <br/>
          <b>Phone:</b> +389 71 338 450 <br/>
          <a class='mailto' href='mailto:evrotommk@gmail.com'>evrotommk@gmail.com</a> <br/>
          <a target="_blank" href='https://www.evrotom.mk'>www.evrotom.mk</a>`,
          sr: `<b>Kontakt:</b> Aneta Stojkova <br/>
          lindenska , Čaška <br/> Makedonija <br/>
          <b>Tel:</b> +389 71 338 450 <br/>
          <a class='mailto' href='mailto:evrotommk@gmail.com'>evrotommk@gmail.com</a> <br/>
          <atarget="_blank"  href='https://www.evrotom.mk'>www.evrotom.mk</a>`
        },
        bul: {
          en: `<b>Contact:</b> Viktoria Moneva <br/>
          Boul. Vladimir Vazov 25, <br/> Sklad 3, 1517 Sofia, Bulgaria <br/>
          <b>Phone:</b> +359 29 950 280 <br/>
          <b>Fax:</b> +359 28 284 395 <br/>
          <a class='mailto' href='mailto:evrotom@abv.bg'>evrotom@abv.bg</a>`,
          sr: `<b>Kontakt:</b> Viktoria Moneva <br/>
          Boul. Vladimir Vazov 25, <br/> Sklad 3, 1517 Sofia, Bugarska <br/>
          <b>Tel:</b> +359 29 950 280 <br/>
          <b>Fax:</b> +359 28 284 395 <br/>
          <a class='mailto' href='mailto:evrotom@abv.bg'>evrotom@abv.bg</a>`
        },
        ukraine: {
          en: `<b>Manager:</b> Pastuh Svetlana <br/>
          Kadetskij Gaj 6, Kiev, <br/> Ukraine <br/>
          <b>Phone:</b> +380 50 525 88 91 <br/> +380 95 138 30 46 <br/>
          <b>Fax:</b> +380 44 270 25 83 <br/>
          <a class='mailto' href='mailto:ceca@ukr.net'>ceca@ukr.net</a>`,
          sr: `<b>Menadžer:</b> Pastuh Svetlana <br/>
          Kadetskij Gaj 6, Kiev, <br/> Ukrajina <br/>
          <b>Tel:</b> +380 50 525 88 91 <br/> +380 95 138 30 46 <br/>
          <b>Fax:</b> +380 44 270 25 83 <br/>
          <a class='mailto' href='mailto:ceca@ukr.net'>ceca@ukr.net</a>`
        },
        baltic: {
          en: `<b>Contact:</b> Janis Raubens <br/>
          <b>Company:</b> SIA Bišu Saime <br/>
          Garozas iela 54, Jelgava, <br/> Latvia <br/>
          <b>Phone:</b> +371 26 855 247 <br/> +371 29 58 18546 <br/>
          <b>Facebook/ Instagram:</b> Bišu saime <br/>
          <a class='mailto' href='mailto:info@bisusaime.lv'>info@bisusaime.lv</a> <br/>
          <a target="_blank" href='https://www.bisusaime.lv'>www.bisusaime.lv</a>`,
          sr: `<b>Kontakt:</b> Janis Raubens <br/>
          <b>Firma:</b> SIA Bišu Saime <br/>
          Garozas iela 54, Jelgava, <br/> Letonija <br/>
          <b>Tel:</b> +371 26 855 247 <br/> +371 29 58 18546 <br/>
          <b>Facebook/ Instagram:</b> Bišu saime <br/>
          <a class='mailto' href='mailto:info@bisusaime.lv'>info@bisusaime.lv</a> <br/>
          <a target="_blank" href='https://www.bisusaime.lv'>www.bisusaime.lv</a>`
        },
        scan: {
          en: `<b>Contact:</b> Haris Sokolovic <br/>
          Önsvalavägen 54, <br/> Staffanstorp, Sweden <br/>
          <b>Phone:</b> +467 07 828 555 <br/>
          <a target="_blank" href='https://www.biredskapscentralen.se'>www.biredskapscentralen.se</a> <br/>`,
          sr: `<b>Kontakt:</b> Haris Sokolovic <br/>
          Önsvalavägen 54, <br/> Staffanstorp, Švedska <br/>
          <b>Tel:</b> +467 07 828 555 <br/>
          <a target="_blank" href='https://www.biredskapscentralen.se'>www.biredskapscentralen.se</a> <br/>`
        },
        cz: {
          en: `<b>Contact:</b> Božík Branislav <br/>
          Priepasne, Slovakia <br/>
          <b>Phone:</b> +421905069003 <br/>
          <a class='mailto' href='mailto:objednavky@vcelieule-bozik.sk'>objednavky@vcelieule-bozik.sk</a> <br/>
          <a target="_blank" href='https://vcelieule-bozik.sk'>vcelieule-bozik.sk</a> <br/>
          <a target="_blank" href='https://vcelarstvi-bozik.cz'>vcelarstvi-bozik.cz</a>`,
          sr: `<b>Kontakt:</b> Božík Branislav <br/>
          Priepasne, Slovačka <br/>
          <b>Tel:</b> +421905069003 <br/>
          <a class='mailto' href='mailto:objednavky@vcelieule-bozik.sk'>objednavky@vcelieule-bozik.sk</a> <br/>
          <a target="_blank" href='https://vcelieule-bozik.sk'>vcelieule-bozik.sk</a> <br/>
          <a target="_blank" href='https://vcelarstvi-bozik.cz'>vcelarstvi-bozik.cz</a>`
        }
      }
    }
  }
})
