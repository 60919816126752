export const root =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? "https://new.evrotom.org"
    : "https://new.evrotom.org";

const imageRoot = root + '/assets/';

export const variables = {
  home:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:8000/"
      : "https://new.evrotom.org/",
  logo: "C:/Users/haunt/Desktop/evrotom/evrotom/src/assets/logo.svg",
  shop: "https://new.evrotom.org/shop",
  myAccount: "https://new.evrotom.org/shop/my-account/",
  loginRoute: "/?rest_route=/simple-jwt-login/v1/auth",
  registration: 'https://new.evrotom.org/shop/registration'
}

export const images = makeImagePaths({
  logo: "logo.svg",
  ticked: "ticked.svg",
  newsletter: "newsletter.svg",
  galleryButton: "gallery-button.svg",
  bee: "bee.svg",
  beekeeper: "beekeeper.svg",
  frame: "/assortment/Frame.svg",
  frame2: "/assortment/Frame2.svg",
  frame3: "/assortment/Frame3.svg",
  frame4: "/assortment/Frame4.svg",
  frame5: "/assortment/Frame5.svg",
  frame6: "/assortment/Frame6.svg",
  awards: "accomplishments/awards.svg",
  handshake: "accomplishments/handshake.svg",
  bulb: "accomplishments/bulb.svg",
  team: "accomplishments/team.svg",
  productArrow: "product-arrow.svg",
  person: "/account/person.svg",
  lock: "/account/lock.svg",
  hexagon: "hexagon.svg",
  arrow: "arrow.svg",
  rectangleDashed: "rectangle-dashed.svg",
  croatia: "/contact/countries/croatia.svg",
  macedonia: "/contact/countries/macedonia.svg",
  bulgaria: "/contact/countries/bulgaria.svg",
  ukraine: "/contact/countries/ukraine.svg",
  letonia: "/contact/countries/letonia.svg",
  lithuania: "/contact/countries/lithuania.svg",
  estonia: "/contact/countries/estonia.svg",
  sweden: "/contact/countries/sweden.svg",
  norway: "/contact/countries/norway.svg",
  denmark: "/contact/countries/denmark.svg",
  slovakia: "/contact/countries/slovakia.svg",
  czechia: "/contact/countries/czechia.svg",
  varolitom: "products/varolitom.jpg",
  separator: "products/separator.jpg",
  kasetna: "products/kasetna.jpg",
})

function makeImagePaths<T>(images: T): T {
  for (const key of Object.getOwnPropertyNames(images)) {
    const val = images[key];
    images[key] = imageRoot + val;
  }
  console.log(images)
  return images;
}

export function makeImagePath(path: string) {
  return imageRoot + path;
}
