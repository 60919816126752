import { Link } from "gatsby"
import * as React from "react"
import "./Footer.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"
import { images } from "../../data/variables"
import { translations } from "../../translations/translations"
import { observer } from "mobx-react-lite"

const Footer = observer(() => {
  return (
    <footer>
      <div className="inner w-90">
        <div className="top d-flex align-items-center">
          <div className="logo">
            <img src={images.logo} alt="Evrotom" />
          </div>
          <div className="social social-mobile">
            <FontAwesomeIcon className="fa" icon={faYoutube} />
            <FontAwesomeIcon className="fa" icon={faFacebookF} />
            <FontAwesomeIcon className="fa" icon={faLinkedinIn} />
          </div>
          <div className="d-flex align-items-end newsletter-container">
            <div className="newsletter d-flex align-items-end">
              <img src={images.newsletter} alt="Evrotom" />
            </div>
            <div className="input">
              <input type="text" placeholder={translations.footer.input} />
              <div className="arrow-send">
                <img src={images.arrow} onClick={() => {}} />
              </div>
              {/* <div className='frame'/> */}
            </div>
          </div>
        </div>
        <div className="middle d-flex">
          <div className="address d-flex flex-column">
            <span>{translations.footer.address.line1}</span>
            <span>{translations.footer.address.line2}</span>
            <span>{translations.footer.address.line3}</span>
            <a className="email" href="mailto:evrotom@hotmail.com">
              evrotom@hotmail.com
            </a>
          </div>
          <div className="links d-flex flex-column">
            <Link to="/contact">{translations.footer.contactUs}</Link>
            <Link to="/shop">{translations.navigation.shop}</Link>
            <Link to="/">{translations.navigation.home}</Link>
          </div>
          <div className="links-two d-flex flex-column">
            <Link to="/contact">{translations.footer.login}</Link>
          </div>
        </div>
        <div className="bottom">
          <div className="copyright d-flex">
            <div className="left d-flex">
              <span>
                © {new Date().getFullYear()} <Link to="/">Evrotom.</Link> All
                Rights Reserved.
              </span>
            </div>
            <div className="right">
              <span>
                Design by{" "}
                <a href="https://highlandesigns.com/">highland studio</a>
              </span>
            </div>
          </div>
          <div className="social d-flex">
            <FontAwesomeIcon className="fa" icon={faYoutube} />
            <FontAwesomeIcon className="fa" icon={faFacebookF} />
            <FontAwesomeIcon className="fa" icon={faLinkedinIn} />
          </div>
        </div>
      </div>
    </footer>
  )
})

export default Footer
