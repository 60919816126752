import { Language } from "./languages"
import { observable, makeObservable, action } from "mobx"
import { getItem, setItem } from "../lib/localStorage"

class Store {
  language: Language = (getItem("language") as Language) ?? "sr"
  initialLoad: boolean = false
  userInfo = null

  constructor() {
    makeObservable(this, {
      language: observable,
      initialLoad: observable,
      changeLanguage: action,
      setInitialLoad: action,
    })
  }

  changeLanguage(lang: Language) {
    this.language = lang
    setItem("language", lang)
  }

  setInitialLoad() {
    this.initialLoad = true
  }
}

export const store = new Store()
